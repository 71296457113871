// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleDown      as fasFaAngleDown      } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleLeft      as fasFaAngleLeft      } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight     as fasFaAngleRight     } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faArrowLeft      as fasFaArrowLeft      } from "@fortawesome/pro-solid-svg-icons/faArrowLeft";
import { faArrowRight     as fasFaArrowRight     } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faClock          as fasFaClock          } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment        as fasFaComment        } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFilePdf        as fasFaFilePdf        } from "@fortawesome/pro-solid-svg-icons/faFilePdf";
import { faFolder         as fasFaFolder         } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faList           as fasFaList           } from "@fortawesome/pro-solid-svg-icons/faList";
import { faMapMarkedAlt   as fasFaMapMarkedAlt   } from "@fortawesome/pro-solid-svg-icons/faMapMarkedAlt";
import { faQuestionCircle as fasFaQuestionCircle } from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";
import { faTag            as fasFaTag            } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faTimes          as fasFaTimes          } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faUserCircle     as fasFaUserCircle     } from "@fortawesome/pro-solid-svg-icons/faUserCircle";

import { faBars           as farFaBars           } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faSearch         as farFaSearch         } from "@fortawesome/pro-regular-svg-icons/faSearch";

import { faFacebookF      as fabFaFacebookF      } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faLinkedinIn     as fabFaLinkedinIn     } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faVimeoV         as fabFaVimeoV         } from "@fortawesome/free-brands-svg-icons/faVimeoV";

/**
 * Add solid icons
 */
library.add(fasFaAngleDown, fasFaAngleLeft, fasFaAngleRight, fasFaArrowLeft, fasFaArrowRight, fasFaClock, fasFaComment, fasFaFilePdf, fasFaFolder, fasFaList, fasFaMapMarkedAlt, fasFaQuestionCircle, fasFaTag, fasFaTimes, fasFaUserCircle);

/**
 * Add regular icons
 */
library.add(farFaBars, farFaSearch);

/**
 * Add brand icons
 */
library.add(fabFaFacebookF, fabFaLinkedinIn, fabFaVimeoV);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
